.roundtwo-container {
    background-color: #ffbd00;
    padding: 2rem;
    border-radius: 1rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .finalist-poster-ids {
    margin-bottom: 1rem;
  }
  
  .roundtwo-form {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 1rem;
  }
  
  .roundtwo-btn {
    background-color: #000000 !important;
    color: #ffbd00 !important;
    border: none;
  }
  
  .roundtwo-btn:hover {
    background-color: #333333;
    color: #ffbd00;
    border: none;
  }
  
  .roundone-container {
    background-color: #ffbd00;
    padding: 2rem;
    border-radius: 1rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .roundone-form {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 1rem;
  }
  
  .roundone-btn {
    background-color: #000000 !important;
    color: #ffbd00 !important;
    border: none;
  }
  
  .roundone-btn:hover {
    background-color: #333333;
    color: #ffbd00;
    border: none;
  }
  